import React from "react"

// Libraries
// import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Carousel from "react-slick"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

// Styles
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

// Utils
import { colors } from "utils/variables/"
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"
import ExternalLink from "components/external-link-with-icon/"
import BackgroundImage from "components/background-image/"

// Icons
import IconArrow from "assets/icons/icon-design-sprint-arrow.inline.svg"
import IconDribbble from "assets/icons/work/icon-dribbble.svg"

const StyledDribbble = styled.section`
  padding: 80px 0;
  overflow: hidden;

  ${breakpoint.medium`
    padding-top: 104px;
  `}

  .dribbble__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 48px;

    ${breakpoint.medium`
      margin-bottom: 72px;
    `}

    .carousel__controls {
      display: none;

      ${breakpoint.medium`
        display: flex;
      `}

      button {
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {

          svg {

            * {
              fill: ${colors.indiblue};
            }
          }
        }

        &:first-child {
          margin-right: 16px;
        }

        &.next {
          transform: rotate(180deg);
        }

        svg {
          width: auto;
          height: 40px;

          * {
            transition: all 0.3s ease;
          }
        }
      }
    }
  }

  .dribbble__carousel {
    max-height: 408px;
    height: 56.25vw;

    .slide {
      padding: 0 8px;

      ${breakpoint.small`
        padding: 0 16px;
      `}
    }
  
    .shot {
      max-width: 544px;
      width: 75vw;
      padding-bottom: 75%;
      background-size: cover;
      cursor: url("${IconDribbble}") 51 51, default;
    }
  }

  .dribbble__link {
    margin-top: 44px;

    ${breakpoint.medium`
      margin-top: 64px;
      text-align: right;
    `}
  }
`

const Dribbble = ({ data: shots }) => {
  const { t } = useTranslation()
  let slider = React.createRef()

  const settings = {
    autoplay: true,
    autoplaySpeed: 4000,
    variableWidth: true,
    centerMode: true,
  }

  const prev = () => {
    slider.slickPrev()
  }

  const next = () => {
    slider.slickNext()
  }

  return (
    <StyledDribbble className="bg--cream">
      <Container>
        <div className="dribbble__title">
          <h3 className="color--midnight">
            <Trans>Take a peek at our recent projects</Trans>
          </h3>

          <div className="carousel__controls">
            <button type="button" onClick={prev}>
              <IconArrow />
            </button>
            <button type="button" className="next" onClick={next}>
              <IconArrow />
            </button>
          </div>
        </div>
      </Container>

      <Carousel
        className="dribbble__carousel"
        {...settings}
        ref={c => (slider = c)}
      >
        {shots.map((shot, index) => (
          <div className="slide">
            <a
              href={shot.url}
              target="_blank"
              rel="noopener noreferrer"
              title={shot.title}
            >
              {shot.localCover.childImageSharp ? (
                <BackgroundImage
                  key={index}
                  className="shot"
                  image={shot.localCover.childImageSharp.gatsbyImageData}
                  alt={shot.title}
                />
              ) : (
                <div
                  className="shot"
                  style={{
                    backgroundImage: `url(${shot.localCover.publicURL})`,
                  }}
                ></div>
              )}
            </a>
          </div>
        ))}
      </Carousel>

      <Container>
        <div className="dribbble__link">
          <ExternalLink
            iconColor={colors.indiblue}
            href="https://dribbble.com/indicius/"
            text={t("Follow us on Dribbble")}
          />
        </div>
      </Container>
    </StyledDribbble>
  )
}

export default Dribbble
