import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

// Libraries
import { Trans } from "gatsby-plugin-react-i18next"
import { motion } from "framer-motion"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container/"
import CaseStudyCard from "components/case-study-card/"
import { Button } from "components/button/"

// Icons
import Card from "assets/icons/work/icon-grid.inline.svg"
import List from "assets/icons/work/icon-list.inline.svg"
import DialogCaret from "assets/icons/icon-dialog-caret-rounded.svg"

// Data
import services from "data/services"

const StyledContainer = styled(Container)`
  max-width: 1100px;
`

const StyledCaseStudies = styled.section`
  padding: 56px 0;

  ${breakpoint.medium`
    padding: 120px 0;
  `}

  .case-studies__view-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 48px;

    .title {
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
    }

    .controls {
      display: flex;
      align-items: center;

      button {
        position: relative;
        width: 20px;
        height: 20px;
        margin-right: 28px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        &:hover,
        &.active {
          svg {
            * {
              stroke: ${colors.midnight};
            }
          }
        }

        svg {
          * {
            transition: all 0.3s;
          }
        }

        &:hover {
          .tooltip {
            transform: translateY(0);
            opacity: 1;
          }
        }

        .tooltip {
          display: none;

          ${breakpoint.medium`
            min-width: ${props => (props.language === "en" ? "96px" : "176px")};
            position: absolute;
            right: ${props => (props.language === "en" ? "-38px" : "-78px")};
            bottom: 150%;
            display: flex;
            justify-content: center;
            margin: 0 auto;
            padding: 4px 12px;
            background-color: ${colors.midnight};
            color: ${colors.white};
            font-size: 16px;
            white-space: nowrap;
            text-align: center;
            border-radius: 8px;
            transform: translateY(16px);
            opacity: 0;
            transition: all 0.3s;
            box-sizing: border-box;


            &::after {
              content: url(${DialogCaret});
              width: 12px;
              height: 8px;
              position: absolute;
              right: 0;
              bottom: 2px;
              left: 0;
              margin: 0 auto;
            }
          `}
        }
      }
    }
  }

  .case-studies__filters {
    max-width: 928px;
    margin: 0 auto;
    position: relative;
    display: flex;
    gap: 7px;
    padding: 16px;
    background-color: ${colors.white};
    scroll-padding: 16px;
    overflow-x: auto;
    scroll-snap-type: x proximity;
    transition: all 0.6s;
    z-index: 10;

    &::-webkit-scrollbar {
      display: none;
    }

    ${breakpoint.small`
      padding: 16px 48px;
      scroll-padding: 48px;
    `}

    ${breakpoint.medium`
      padding: 16px 0;
    `}

    ${breakpoint.large`
      overflow: visible;
    `}

    .filter {
      padding: 4px 16px;
      background-color: ${colors.stone};
      color: ${colors.white};
      scroll-snap-align: start;
      white-space: nowrap;
      cursor: pointer;
      transition: all 0.3s ease;

      &--active,
      &:hover {
        background-color: ${colors.indiblue};
      }
    }
  }

  .case-studies {
    margin-top: 8px;
  }

  .case-studies__load-more {
    margin-top: 56px;
    text-align: center;

    ${breakpoint.medium`
      margin-top: 64px;
    `}
  }
`

function transformCategories(arr) {
  const categoryMap = {
    "Identidad Visual": "Visual Branding",
    "Desarrollo Web": "Website Development",
    "Diseño UX/UI": "UX/UI Design"
  };

  return arr.map(item => {
    const newCategories = item.categories.map(category => categoryMap[category] || category);
    return { ...item, categories: newCategories };
  });
}

const CaseStudies = props => {
  const { data: projects, filter, language } = props

  const transformedProjects = transformCategories(projects);

  const [activeFilter, setActiveFilter] = useState(filter || "all")
  const [categories, setCategories] = useState([])
  const [caseStudies, setCaseStudies] = useState(projects)
  const [view, setView] = useState("card")
  const [quantityVisible, setQuantityVisible] = useState(20)
  const [isScrollingUp, setIsScrollingUp] = useState(false)
  const [lastScrollPosition, setLastScrollPosition] = useState(0)
  const [hasMounted, setHasMounted] = useState(false)

  const ref = useRef()


  useEffect(() => {
    setCategories(
      services.filter(service =>
        projects.find(caseStudy => caseStudy.categories.includes(service.title))
      )
    )
  }, [])

  useEffect(() => {
    setHasMounted(true)
  }, [])

  useEffect(() => {
    const newCaseStudies =
      activeFilter === "all"
        ? transformedProjects
        : transformedProjects.filter(caseStudy =>
          caseStudy.categories.includes(activeFilter)
        )

    setCaseStudies(newCaseStudies)

    if (ref.current && hasMounted) {
      ref.current.scrollIntoView({
        behavior: "smooth",
      })
    }
  }, [activeFilter])

  useEffect(() => {
    if (activeFilter !== "all") {
      const dataLayer = window.dataLayer || []

      dataLayer.push({
        event: "service_filter",
        service_name: activeFilter,
      })
    }
  }, [activeFilter])

  useEffect(() => {
    if (typeof window !== undefined) {
      if (activeFilter !== "all") {
        const category = categories.find(
          category => category.title === activeFilter
        )

        if (category) {
          window.history.pushState({}, "", `/work/${category.slug}`)
        }
      }
    }
  }, [activeFilter])

  useEffect(() => {
    const handleScroll = () => {
      const { scrollY } = window

      setIsScrollingUp(lastScrollPosition > scrollY)

      setLastScrollPosition(scrollY)
    }

    document.addEventListener("scroll", handleScroll, { passive: true })

    return () =>
      document.removeEventListener("scroll", handleScroll, { passive: true })
  })

  return (
    <StyledCaseStudies
      ref={ref}
      language={language}
      isScrollingUp={isScrollingUp}
    >
      <StyledContainer>
        <div className="case-studies__view-controls">
          <p className="title">
            <Trans>Featured Projects</Trans>
          </p>
          <div className="controls">
            <button
              type="button"
              className={view === "card" ? "active" : ""}
              onClick={() => setView("card")}
            >
              <Card />
              <span className="tooltip">
                <Trans>Card View</Trans>
              </span>
            </button>
            <button
              type="button"
              className={view === "list" ? "active" : ""}
              onClick={() => setView("list")}
            >
              <List />
              <span className="tooltip">
                <Trans>List View</Trans>
              </span>
            </button>
          </div>
        </div>
      </StyledContainer>

      <div className="case-studies__filters">
        <button
          type="button"
          className={`filter ${activeFilter === "all" &&
            "filter--active"} paragraph--detail font-weight--500`}
          onClick={() => setActiveFilter("all")}
        >
          <Trans>All</Trans>
        </button>

        {categories.map(category => (
          <button
            type="button"
            className={`filter ${activeFilter === category.title &&
              "filter--active"} paragraph--detail font-weight--500`}
            onClick={() => setActiveFilter(category.title)}
          >
            <Trans>{category.title}</Trans>
          </button>
        ))}
      </div>

      <StyledContainer>
        <motion.div
          initial="hidden"
          variants={{
            visible: {
              transition: {
                staggerChildren: 0.5,
              },
            },
          }}
          animate="visible"
          className="case-studies row"
        >
          {caseStudies.slice(0, quantityVisible).map((project, index) => (
            <motion.div
              variants={{
                hidden: {
                  y: 32,
                  scale: 0.95,
                  opacity: 0,
                },
                visible: {
                  y: 0,
                  scale: 1,
                  opacity: 1,
                },
              }}
              transition={{
                bounce: false,
                delay: index * 0.125,
              }}
              key={`${project.title} ${activeFilter}`}
              className="col-12 mb-4 mb-md-5"
            >
              <CaseStudyCard view={view} {...project} />
            </motion.div>
          ))}

          {quantityVisible < projects.length && (
            <div className="case-studies__load-more">
              <Button
                backgroundColor={colors.indiblue}
                color={colors.white}
                hoverBackgroundColor={colors.midnight}
                onClick={() => setQuantityVisible(quantityVisible + 20)}
              >
                <Trans>Load more</Trans>
              </Button>
            </div>
          )}
        </motion.div>
      </StyledContainer>
    </StyledCaseStudies>
  )
}

export default CaseStudies
