/**
 *
 */
module.exports = [
  { title: "UX/UI Design", slug: "ux-ui-design", description: "" },
  {
    title: "Brand Narrative Workshop",
    slug: "brand-narrative-workshop",
    description: "",
  },
  { title: "Visual Branding", slug: "visual-branding", description: "" },
  {
    title: "Website Development",
    slug: "website-development",
    description: "",
  },
  { title: "Design Sprint", slug: "design-sprint", description: "" },
]
