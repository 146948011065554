import React from "react"

// Libraries
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImg from "gatsby-background-image"

const BackgroundImage = ({ image, className, rest }) => {
  const bgImg = convertToBgImage(image)

  return (
    <BackgroundImg
      {...bgImg}
      className={className}
      {...rest}
      preserveStackingContext
    />
  )
}

export default BackgroundImage
