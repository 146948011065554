import React from "react"

//Libraries
import { useTranslation } from "gatsby-plugin-react-i18next"

// Components
import PageHero from "components/page-hero/"

const Hero = () => {
  
  const {t} = useTranslation()

  return (
    <PageHero
    title={t("Explore projects we’ve made together with our amazing clients.")}
    highlight={t("Showtime.")}
  />
  )
}

export default Hero
