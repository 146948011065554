import React from "react"

// Libraries
import { graphql } from "gatsby"

// Utils
import { colors } from "utils/variables/"
import { useTranslation } from "gatsby-plugin-react-i18next"

// Layout
import Layout from "layouts/layout-primary"

// Context
import ContextConsumer from "components/context/"

// Components
import Seo from "components/seo/"

// Page Sections
import Hero from "sections/work/hero"
import Projects from "sections/work/case-studies"
import Dribbble from "sections/work/dribbble"

// Data
import caseStudies from "data/case-studies"

const Work = ({ data, pageContext }) => {
  const {
    allDribbbleShot: { nodes: shots },
    allContentfulCaseStudy: { nodes: newCaseStudies },
  } = data

  const { t } = useTranslation()

  const { language, filter } = pageContext

  const combineCaseStudies = newCaseStudies.concat(caseStudies)

  return (
    <Layout menuColor={colors.midnight}>
      <ContextConsumer>
        {({ data, set }) => {
          if (data.useYellowFooter) {
            set({
              useYellowFooter: false,
            })
          }
          if (data.darkMenu) {
            set({ darkMenu: false })
          }
          if (!data.showFooter) {
            set({ showFooter: true })
          }
          if (data.simplifiedFooter) {
            set({
              simplifiedFooter: false,
            })
          }
        }}
      </ContextConsumer>
      <Seo
        title={t(
          filter
            ? `${filter} projects - Indicius Design Agency`
            : `Work - Indicius Design Agency`
        )}
        description={t(
          "We design websites, apps, and brand identities for amazing clients like Samsung, Mount Sinai, Columbia University, AON, Red Bull and... maybe you are the next."
        )}
      />
      <Hero />
      <Projects data={combineCaseStudies} filter={filter} language={language} />
      <Dribbble data={shots} />
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    allDribbbleShot(limit: 3) {
      nodes {
        id
        title
        url
        localCover {
          childImageSharp {
            gatsbyImageData(width: 544, quality: 100)
          }
          publicURL
        }
      }
    }

    allContentfulCaseStudy(
      filter: {
        node_locale: { eq: $language }
        title: { ne: "Case Study Template" }
      }
    ) {
      nodes {
        title
        client
        slug
        year
        node_locale
        categories: tags
        new
        imagen: covers {
          gatsbyImageData(height: 320, width: 464, quality: 100)
          file {
            contentType
            url
          }
        }
      }
    }
  }
`

export default Work
